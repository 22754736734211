import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {Box, TextField} from "@mui/material";
import {useState} from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SearchUserDialog({open, handleClose, users}) {

    const [filter, setFilter] = useState('');

    const checkinUser = (e, rowData) => {
        console.log(rowData.id);
        setFilter('');
        handleClose({hash: rowData.id});
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Cerca
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleClose}>
                        chiudi
                    </Button>
                </Toolbar>
            </AppBar>
            <Box sx={{pt: 10, mx: 2}}>
                <TextField id="outlined-basic" label="Filtra" variant="outlined" sx={{width: '100%'}} onChange={(e) => setFilter(e.target.value)} />
            </Box>
            {filter.length < 2 && <Typography sx={{p: 2}} variant={"subtitle2"}>Inserisci almeno due lettere</Typography>}
            <List sx={{pt: 2}}>
                {filter.length >= 2 && users
                    .filter((user) => user?.nome?.toLowerCase()?.includes(filter.toLowerCase()) || user?.cognome?.toLowerCase()?.includes(filter.toLowerCase()) || user?.email1?.toLowerCase()?.includes(filter.toLowerCase()))
                    .sort((a, b) => a.email1?.localeCompare(b.email1))
                    .map((val) =>
                    <ListItem
                        key={val.id}
                        secondaryAction={
                            <IconButton edge="end" aria-label="checkin" onClick={(e) => checkinUser(e, val)}>
                                <CheckCircleIcon sx={{ color: 'green', fontSize: '2.5rem' }} />
                            </IconButton>
                        }
                    >
                        <ListItemText
                            primary={val.nome +  ' ' + val.cognome}
                            secondary={val.email1}
                        />
                    </ListItem>
                )}
            </List>
        </Dialog>
    );
}
