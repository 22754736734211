import './App.css';
import {Route, Routes} from "react-router-dom";
import {CssBaseline, ThemeProvider, useMediaQuery} from "@mui/material";
import {pleshTheme} from "./theme/pleshTheme";
import Mobile from "./pages/Mobile";
import Home from "./pages/Home";

function App() {
    const mobile = useMediaQuery('(max-width: 1200px)')
    return (
        <ThemeProvider theme={pleshTheme}>
            <CssBaseline/>
            <Routes>
                <Route path={'/'} element={mobile ? <Mobile/> : <Home/>}></Route>
            </Routes>
        </ThemeProvider>
    );
}

export default App;
