import React, {useEffect, useState} from "react";
import {
    Alert, AlertTitle,
    Box,
    Container, Typography
} from "@mui/material";
import CheckinAppBar from "../components/CheckinAppBar";
import {QrReader} from 'react-qr-reader';
import {API_URL} from "../config";
import axios from "axios";
import SearchUserDialog from "../components/SearchUserDialog";

const Mobile = () => {

    const [count, setCount] = useState(0);
    const [data, setData] = useState('');
    const [user, setUser] = useState(null);
    const [userList, setUserList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [error, setError] = useState(false);
    const constraints = {
        facingMode: "environment"
    };

    const getUser = async (id) => {
        const _user = await axios.get(API_URL + "user/" + id);
        setUser(_user.data)
    };

    const checkinUser = async (id) => {
        setError(false);
        try {
            await axios.get(API_URL + "user/" + id + "/checkin");
            return true;
        } catch (e) {
            console.log(e);
            setError(true);
            return false;
        }
    };

    useEffect(() => {
        fetchData().then()
    }, [user])

    useEffect(() => {
        if (data !== '') {
            checkinUser(data).then(resCheckin => {
                if (resCheckin) {
                    getUser(data).then(_ => setCount(count + 1))
                }
            });
        }
    }, [data])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (res) => {
        if (res && res.hash) {
            checkinUser(res.hash)
                .then(resCheckin => {
                    if (resCheckin) {
                        getUser(res.hash).then(_ => setCount(count + 1))
                    }
                });
        }
        setOpen(false);
    };

    async function fetchData() {
        const _userList = await axios.get(API_URL + "users");
        setUserList(_userList.data)
    }

    return (
        <main>
            <CheckinAppBar mobile={true} handleDialogOpen={handleClickOpen} />
            <Box>
                <Container maxWidth={false}>
                    <Typography variant={"subtitle1"} sx={{mt: 2}} textAlign={"center"}>
                        {count} check-ins in questa sessione
                    </Typography>
                    <QrReader
                        onResult={(result, error) => {
                            if (!!result) {
                                setData(result?.text);
                            }

                            if (!!error) {
                                //console.info(error);
                            }
                        }}
                        videoContainerStyle={{width: '100%', minHeight: '65vh'}}
                        constraints={constraints}
                    />
                </Container>
                {count > 0 && !error && <Alert severity="success" sx={{m: 2}}>
                    <AlertTitle>{user?.nome} {user?.cognome}</AlertTitle>
                    {user?.email1}</Alert>}
                {error && <Alert severity={"error"} sx={{m: 2}}>
                    <AlertTitle>QR Code non valido.</AlertTitle>
                </Alert>}
            </Box>
            <SearchUserDialog open={open} handleClose={handleClose} users={userList} />
        </main>
    )
}

export default Mobile
