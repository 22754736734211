import {createTheme} from "@mui/material";
import { itIT } from '@mui/material/locale';

export const pleshTheme = createTheme({
    components: {
        MuiLinearProgress: {
            styleOverrides:{
                root:{
                    width:'100% !important'
                }
            }
        }
    },

    palette: {
        mode: 'light',
        primary: {
            main: '#262531'
        }
    },

    itIT,
});
