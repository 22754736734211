import React, {useEffect, useState} from "react";
import {
    Box, Card, IconButton, Stack
} from "@mui/material";
import axios from "axios";
import {API_URL} from "../config";
import { DataGrid } from '@mui/x-data-grid';
import CheckinAppBar from "../components/CheckinAppBar";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
import {Refresh} from "@mui/icons-material";

const Home = () => {

    const checkinUser = async (id) => {
        await axios.get(API_URL + "user/" + id + "/checkin");
        await fetchData();
    };

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.5,
        },
        {
            field: 'pos_anag',
            headerName: 'POS_ANAG',
            flex: 0.5,
        },
        {
            field: 'nome',
            headerName: 'Nome',
            flex: 1,
        },
        {
            field: 'cognome',
            headerName: 'Cognome',
            flex: 1,
        },
        {
            field: 'email1',
            headerName: 'Email',
            flex: 1,
        },
        {
            field: 'settore',
            headerName: 'Settore',
            flex: 1,
        },
        {
            field: 'riscontroGiorno1',
            headerName: 'Partecipa',
            flex: 1,
        },
        {
            field: 'checkin',
            headerName: 'Accesso',
            flex: 0.5,
        },
        {
            field: 'updatedAt',
            headerName: 'Checkin',
            flex: 1,
            valueFormatter: (params) => {
                return params.value ? new Date(params.value?._seconds * 1000).toLocaleTimeString('it-IT') : '';
            }
        },
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking

                    const api = params.api;
                    const thisRow = {};

                    api
                        .getAllColumns()
                        .filter((c) => c.field !== "__check__" && !!c)
                        .forEach(
                            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                        );

                    checkinUser(thisRow.id).then();
                };

                return !params.row.checkin ? <Button variant={"outlined"}
                                                     endIcon={<CheckCircleIcon sx={{ color: 'green', fontSize: '2.5rem' }} />}
                                                     onClick={onClick}>Check-in</Button> : <></>;
            }
        },
    ];

    const [userList, setUserList] = useState([]);
    const [total, setTotal] = useState(0);
    const [checkins, setCheckins] = useState(0);
    const [confirmed, setConfirmed] = useState(0);

    useEffect(() => {
        fetchKpis().then()
        fetchData().then()
    }, [])

    async function fetchData() {
        const _userList = await axios.get(API_URL + "allUsers");
        setUserList(_userList.data)
    }

    async function fetchKpis() {
        const _kpis = await axios.get(API_URL + "stats");
        setTotal(_kpis.data.total);
        setCheckins(_kpis.data.checkin);
        setConfirmed(_kpis.data.confirmed);
    }

    return (
        <main>
            <CheckinAppBar mobile={false} />
            <Stack sx={{px: 5, mt: 4}} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Stack direction={"row"} justifyContent={"start"} alignItems={"center"}>
                    <Button variant={"outlined"} size={"medium"} color={"primary"} sx={{mr: 3}}
                    onClick={() => fetchKpis()}>
                        <Refresh />
                    </Button>
                    <Box>
                        <Typography variant={"h6"}>Registrati: {total}</Typography>
                        <Typography variant={"h6"}>Presenze confermate: {confirmed}</Typography>
                        <Typography variant={"h6"}>Checkin: {checkins}</Typography>
                    </Box>
                </Stack>
                <Button variant={"contained"} onClick={fetchData}>Aggiorna Lista</Button>
            </Stack>
            <Box sx={{ height: 700, width: '100%', p: 4 }}>
                <DataGrid
                    rows={userList}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                />
            </Box>
        </main>
    )
}

export default Home
